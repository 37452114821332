import React from 'react';
import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type StyledAbsImageProps = {
    left?: string;
    right?: string;
    width: number;
    adjust?: [string, string];
    zIndex?: number;
};

const StyledAbsImage = styled.img<StyledAbsImageProps>`
    position: absolute;
    width: ${(props) => props.width}px;
    z-index: ${(props) => props.zIndex ?? 0};

    ${({ left }) =>
        left &&
        css`
            left: ${left};
        `}

    ${({ right }) =>
        right &&
        css`
            right: ${right};
        `}

    ${({ adjust }) => {
        if (!adjust) {
            return;
        }

        const [x, y] = adjust;
        return css`
            transform: translate(${x}, ${y});
        `;
    }}
`;

const Pivot = styled.div`
    width: 100vw;
    height: 0;
    position: relative;
`;

type AbsImageProps = {
    image: string;
    width: number;
    left?: string;
    right?: string;
    adjust?: [string, string];
    zIndex?: number;
};

const AbsImage: FC<AbsImageProps> = ({
    image,
    width,
    left,
    right,
    adjust,
    zIndex,
}) => {
    return (
        <Pivot>
            <StyledAbsImage
                src={image}
                width={width}
                left={left}
                right={right}
                adjust={adjust}
                zIndex={zIndex}
            />
        </Pivot>
    );
};

export default AbsImage;
