import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

export const RollingCharacters: React.VFC = () => {
    const queryResult = useStaticQuery<{
        charactersImage: {
            childImageSharp: {
                fixed: {
                    src: string;
                    width: number;
                };
            };
        };
    }>(graphql`
        query {
            charactersImage: file(
                relativePath: { eq: "images/vox-characters.png" }
            ) {
                childImageSharp {
                    fixed(height: 350) {
                        src
                        width
                    }
                }
            }
        }
    `);

    const { src: charactersImage, width: charactersImageWidth } =
        queryResult.charactersImage.childImageSharp.fixed;

    return (
        <div>
            <div
                css={css`
                    overflow: hidden;
                `}
            >
                <div
                    css={css`
                        font-size: 0;

                        display: flex;
                        flex-direction: row;

                        animation: roll 30s linear infinite;

                        @keyframes roll {
                            0% {
                                transform: translateX(0);
                            }

                            100% {
                                transform: translateX(
                                    -${charactersImageWidth}px
                                );
                            }
                        }
                    `}
                >
                    <img src={charactersImage} alt="Vox characters 1" />
                    <img src={charactersImage} alt="Vox characters 2" />
                    <img src={charactersImage} alt="Vox characters 3" />
                </div>
            </div>
        </div>
    );
};
