import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';

export const ChatBubble: React.FC<{
    type?: 'large' | 'small';
    x?: number;
    y?: number;
    scale?: number;
}> = ({ children, type = 'small', x, y, scale }) => {
    const queryResult = useStaticQuery<{
        smallChatImage: { childImageSharp: { gatsbyImageData: ImageDataLike } };
        largeChatImage: { childImageSharp: { gatsbyImageData: ImageDataLike } };
    }>(graphql`
        query {
            smallChatImage: file(
                relativePath: { eq: "images/chat-small.png" }
            ) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            largeChatImage: file(
                relativePath: { eq: "images/chat-large.png" }
            ) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    const smallChatImage = getImage(
        queryResult.smallChatImage.childImageSharp.gatsbyImageData
    );
    const largeChatImage = getImage(
        queryResult.largeChatImage.childImageSharp.gatsbyImageData
    );

    const chatBubbleImage = type === 'large' ? largeChatImage : smallChatImage;

    const width = chatBubbleImage ? chatBubbleImage.width * (scale ?? 1) : 0;
    const height = chatBubbleImage ? chatBubbleImage.height * (scale ?? 1) : 0;

    return chatBubbleImage ? (
        <div
            css={css`
                position: absolute;
                left: ${-width / 2 + (x ?? 0)}px;
                bottom: ${-height / 2 + (y ?? 0)}px;

                width: ${width}px;
                height: ${height}px;
            `}
        >
            <GatsbyImage
                image={chatBubbleImage}
                alt="Chat bubble"
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                `}
            />
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    position: absolute;
                    left: 0;
                    bottom: 25%;
                    width: 100%;
                    height: 75%;

                    font-family: pixel;
                    font-size: ${height / 2}px;
                `}
            >
                {children}
            </div>
        </div>
    ) : (
        <div />
    );
};
